import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DataAttribute } from "./models/utils.model";
import { useApi } from "./hooks/useApi/useApi";
import { useFlow } from "./hooks/useFlow/useFlow";
import CcWidgetPersonalData from "./component/ccWidgetPersonalData/CcWidgetPersonalData";

declare global {
  interface Window {
    buildSuccessFormPageName(form: string): any;
  }
}

function App(dataOcms: any) {
  const { i18n } = useTranslation();

  const { startAxiosResponseInterceptor } = useApi();
  const { loadConfiguration } = useFlow();

  const configurationAndStartup = useCallback(async () => {
    // Run axios response interceptor
    startAxiosResponseInterceptor();

    // Load configuration
    await loadConfiguration();

    // Get attribute
    const langParam = document.documentElement.getAttribute("lang") || "de";

    //Check if a lang is passed, and set it if valid
    if (langParam && ["it", "fr", "de"].includes(langParam)) {
      i18n.changeLanguage(langParam);
    } else i18n.changeLanguage("de");
  }, [i18n, startAxiosResponseInterceptor, loadConfiguration]);

  useEffect(() => {
    configurationAndStartup();
  }, [configurationAndStartup]);

  const dataAttribute: DataAttribute = dataOcms.dataOcms;

  const renderWidget = useMemo(() => {
    switch (dataAttribute.dataWidget) {
      case "cc-personal-data":
        return <CcWidgetPersonalData dataAttribute={dataAttribute} />;

      default:
        return <div>Form not found</div>;
    }
  }, [dataAttribute]);

  return <div className="App">{renderWidget}</div>;
}

export default App;
