export const TRANSLATIONS_IT = {
  screens: {
    personalData: {
      mr: "Signore",
      mrs: "Signora",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Indirizzo e-mail",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      gtcAgree: "Acconsento al <Terms>trattamento dei miei dati</Terms>.",
      gtcAgreeDesc: "Immettendo i tuoi dati nei campi in questa schermata autorizzi automaticamente Cornèrcard (Cornèr Banca SA) a trattare i tuoi dati personali per essere contattato (via e-mail o telefonicamente), cosi come per ricevere informazioni e messaggi pubblicitari, relativamente a prodotti e a servizi che, ad avviso di Cornèrcard, potrebbero interessarti (informazioni e marketing diretto). I tuoi dati personali saranno trattati con la massima cura e in conformità alle disposizioni della Legge federale svizzera sulla protezione dei dati.<br/><br/>Puoi eseguire in qualsiasi momento l'opt-out dal ricevimento di informazioni (blocco dei messaggi pubblicitari) o revocare a livello generale qualsiasi consenso precedente che puoi aver rilasciato al trattamento dei dati per finalità di marketing, inviando a Cornèr una richiesta scritta a tal fine, anche per e-mail.",
      termsLink: "https://www.cornertrader.ch/export/sites/cornertrader/.content/.galleries/downloads/website/New-General-Terms-and-Conditions.pdf",
      submit: "Richiedila ora",
    },
  },
  errors: {
    input: {
      invalidPattern: "Formato non valido",
      minLength: "Sono necessari almeno {{min}} caratteri",
      maxLength: "È consentito un massimo di {{max}} caratteri",
      minPhoneLength: "Il numero di telefono deve essere composto da almeno {{min}} cifre.",
      required: "Campo obbligatorio",
      mismatch: "{{fieldName}} non corrisponde",
    },
    submit: "Si è verificato un errore",
  },
  captchaDisclaimer: "Questo sito è protetto da reCAPTCHA e si applicano le <br/> <0>norme sulla privacy</0> e i <1>termini di servizio</1> di Google.",
};
