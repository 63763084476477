import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./App";
import "./index.css";
import { DataAttribute } from "./models/utils.model";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const roots = document.getElementsByClassName("reactroot");

Array.from(roots).forEach((item) => {
  const dataOcms: DataAttribute = {
    // Global
    dataWidget: item.getAttribute("data-widget") || "",
    ccOnboarding: item.getAttribute("cc-onboarding") || "",
    halfContainer: item.clientWidth < 800 ? true : false,
  };

  ReactDOM.createRoot(item).render(
    <Provider store={store}>
      <React.StrictMode>
        <App dataOcms={dataOcms} />
      </React.StrictMode>
    </Provider>
  );
});
