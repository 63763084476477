import axios, { AxiosResponse } from "axios";
import { useCallback } from "react";
import { Request } from "../../models/request/request.model";
import { Response } from "../../models/response/response.model";
import { useLogger } from "../useLogger/useLogger";

export const useApi = () => {
  const { log, logError } = useLogger();

  const startAxiosResponseInterceptor = useCallback(() => {
    log("log", "useApi.tsx", "startAxiosResponseInterceptor...");
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        //Http error
        logError("useApi.tsx", "axiosResponseInterceptor", error);
      }
    );
  }, [log, logError]);

  const configuration = useCallback(async () => {
    try {
      const response: AxiosResponse<Response> = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "configuration"
      );
      return response;
    } catch (error) {
      logError("useApi.tsx", "configuration resp...", error);
    }
  }, [logError]);

  const stepLoader = useCallback(
    async (body: Request) => {
      try {
        const response: AxiosResponse<Response> = await axios.post(
          process.env.REACT_APP_API_ENDPOINT + "webForm",
          body
        );
        return response;
      } catch (error) {
        logError("useApi.tsx", "webForm resp...", error);
      }
    },
    [logError]
  );

  return {
    startAxiosResponseInterceptor,
    stepLoader,
    configuration,
  };
};
