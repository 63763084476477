import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  CheckBox,
  FieldGroup,
  FieldRow,
  FieldSet,
  Input,
  Radio,
  SelectBox,
} from "react-corner-ds";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { countriesDe } from "../../assets/countries/de";
import { countriesFr } from "../../assets/countries/fr";
import { countriesIt } from "../../assets/countries/it";
import {
  CcData,
  PersonalDataFormInfo,
} from "../../models/request/data/data.model";
import { CountryItem, DataAttribute } from "../../models/utils.model";
import style from "./CcWidgetPersonalData.module.css";
import { definePatternByName } from "../../utils/constants";
// import GooglCaptchaDisclaimer from "../googleCaptchaDisclaimer/googleCaptchaDisclaimer";

interface ICcWidgetPersonalData {
  dataAttribute: DataAttribute;
}

const CcWidgetPersonalData: React.FC<ICcWidgetPersonalData> = ({
  dataAttribute,
}) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const selectedLang = useMemo(
    () => i18n.resolvedLanguage,
    [i18n.resolvedLanguage]
  );

  // set trk Adobe Analytics
  const buttonSubmit = document.getElementsByClassName("trkPersonalData");
  buttonSubmit[0]?.setAttribute("data-trk-btn-click", "submit-personalData");

  const redirect = (target: string) => {
    window.location.assign(target);
  };

  const countries = useMemo(() => {
    switch (selectedLang) {
      case "fr":
        return countriesFr;

      case "it":
        return countriesIt;

      default:
        return countriesDe;
    }
  }, [selectedLang]);

  const validity = useCallback(
    (error: FieldError | undefined, isTouched: boolean) =>
      !error && isTouched ? true : undefined,
    []
  );

  const prefixItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name, "+" + item.phone_code],
      value: "+" + item.phone_code,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const { handleSubmit, formState, control } = useForm<PersonalDataFormInfo>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      Salutation: "001",
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: {
        CountryCode: "+41",
        LocalNumber: "",
        Type: "Mobile",
      },
    },
  });

  const submitHandler = async (data: PersonalDataFormInfo) => {
    let body: CcData = {
      Cc_Customer: {
        Salutation: data.Salutation,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
        Phone: {
          CountryCode: data.Phone.CountryCode,
          LocalNumber: data.Phone.LocalNumber,
          Type: "Mobile",
        },

        TCAccepted: data.TCAccepted,
      },
    };

    console.log("CC-ON", dataAttribute.ccOnboarding);

    setLoading(true);
    window.sessionStorage.setItem("Cc_SessionData", JSON.stringify(body));
    redirect(dataAttribute.ccOnboarding || "");
    setLoading(false);
  };

  const genderRadio = useMemo(
    () => (
      <Controller
        control={control}
        name="Salutation"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name, value } }) => (
          <Radio
            name={name}
            value={value}
            options={[
              { text: t`screens.personalData.mr`, _value: "001" },
              { text: t`screens.personalData.mrs`, _value: "003" },
            ]}
            onChange={(updateValue) => {
              onChange(updateValue);
            }}
          />
        )}
      />
    ),
    [control, t]
  );

  const firstNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="FirstName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("FirstName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.personalData.firstName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const lastNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="LastName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("LastName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.personalData.lastName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const emailInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Email"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`screens.personalData.emailRequired`,
          },
          pattern: {
            value: definePatternByName("Email"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.personalData.email` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            type="email"
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const prefixSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Phone.CountryCode"
        defaultValue={"+41"}
        render={({ field: { onChange, name, value } }) => (
          <SelectBox
            cssClass={"prefix"}
            value={value}
            previewIndex="1"
            items={prefixItems}
            name={name}
            onChange={(item) => {
              onChange(item.value);
            }}
          />
        )}
      />
    ),
    [control, prefixItems]
  );

  const phoneInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Phone.LocalNumber"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`screens.personalData.phoneRequired`,
          },
          pattern: {
            value: definePatternByName("Phone"),
            message: t`errors.input.invalidPattern`,
          },
          minLength: {
            value: 9,
            message: t(`errors.input.minPhoneLength`, {
              min: "9",
            }),
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            type="tel"
            name={name}
            customCssClass={style.customPhoneInput}
            label={t`screens.personalData.phoneNr` || ""}
            placeholder="00 000 00 00"
            cssClass="prefixed"
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={11}
            value={value}
          >
            {prefixSelectBox}
          </Input>
        )}
      />
    ),
    [control, prefixSelectBox, t, validity]
  );

  const toggleContent = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const termsInput = useMemo(
    () => (
      <Controller
        control={control}
        name="TCAccepted"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name } }) => (
          <>
            <FieldRow customCssClass={style.disclaimerRow}>
              <FieldGroup customCssClass={style.disclaimerCheckBox}>
                <CheckBox
                  name={name}
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                  }}
                />
              </FieldGroup>
              <FieldGroup customCssClass={style.disclaimer}>
                <Trans
                  i18nKey={t`screens.personalData.gtcAgree`}
                  components={{
                    Terms: (
                      <button
                        type="button"
                        className={style.disclaimerButton}
                        onClick={toggleContent}
                      />
                    ),
                  }}
                />
                <i
                  className={
                    isExpanded
                      ? "cticonapp-collapse-arrow"
                      : "cticonapp-expand-arrow"
                  }
                  onClick={toggleContent}
                />
                {isExpanded && (
                  <div className={style.expContent}>
                    <Trans i18nKey={t`screens.personalData.gtcAgreeDesc`} />
                  </div>
                )}
              </FieldGroup>
            </FieldRow>
          </>
        )}
      />
    ),
    [control, isExpanded, t, toggleContent]
  );

  return (
    <div className={style.action}>
      <div className={style.personalDataForm}>
        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
          <FieldSet>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{genderRadio}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{firstNameInput}</FieldGroup>
              <FieldGroup>{lastNameInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{emailInput}</FieldGroup>
              <FieldGroup>{phoneInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{termsInput}</FieldGroup>
            </FieldRow>
          </FieldSet>
          <div className={style.btnHolder}>
            <Button
              text={t`screens.personalData.submit`.toUpperCase()}
              disabled={!formState.isValid || loading}
              type="submit"
              icon={
                loading
                  ? { icon: "loader", position: "right", spin: true }
                  : undefined
              }
              cssClass={["secondary"]}
              // set trk Adobe Analytics
              customCssClass={"trkPersonalData"}
            />
          </div>

          {/* <GooglCaptchaDisclaimer></GooglCaptchaDisclaimer> */}
        </form>
      </div>
    </div>
  );
};

export default CcWidgetPersonalData;
