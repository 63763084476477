export const TRANSLATIONS_DE = {
  screens: {
    personalData: {
      mr: "Herr",
      mrs: "Frau",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Adresse",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired: "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      gtcAgree: "Ich stimme der <Terms>Verarbeitung meiner Daten</Terms>.",
      gtcAgreeDesc: "Durch die Eingabe Ihrer Daten auf diesem Bildschirm ermächtigen Sie Cornèrcard (Cornèr Bank AG) automatisch, Ihre persönlichen Daten zu bearbeiten, um (per E-Mail oder Telefon) kontaktiert zu werden, sowie um Informationen und Werbemitteilungen bezüglich Produkten und Dienstleistungen zu erhalten, die nach Auffassung von Cornèrcard für Sie von Interesse sein könnten (Information und Direktmarketing). Ihre persönlichen Daten werden mit grösster Sorgfalt und gemäss den Bestimmungen des schweizerischen Bundesgesetzes über den Datenschutz vertraulich behandelt.<br/><br/>Sie können jederzeit auf die Zusendung von Informationen verzichten (Werbesperren) oder die erteilte Einwilligung in die Datenbearbeitung zu Marketingzwecken durch entsprechende schriftliche Mitteilung (auch per E-Mail) an Cornèr generell widerrufen.",
      termsLink: "https://www.cornertrader.ch/export/sites/cornertrader/.content/.galleries/downloads/website/New-General-Terms-and-Conditions.pdf",
      submit: "Karte beantragen",
    },
  },
  errors: {
    input: {
      invalidPattern: "Ungültiges Format",
      minLength: "Mindestens {{min}} Zeichen erforderlich",
      maxLength: "Maximal {{max}} Zeichen erlaubt",
      minPhoneLength: "Die Telefonnummer muss aus mindestens {{min}} Ziffern bestehen.",
      required: "Pflichtfeld",
      mismatch: "{{fieldName}} stimmt nicht überein",
    },
    submit: "Es ist ein Fehler aufgetreten",
  },
  captchaDisclaimer: "Diese Website ist durch reCAPTCHA geschützt, es gelten<br/>die Google <0>Datenschutz-</0> und <1>Nutzungsbedingungen</1>.",
};
