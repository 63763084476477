import { useCallback } from "react";
import { useAppSelector } from "../../redux/hooks";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const useGrecaptcha = () => {
  const appConf = useAppSelector((state) => state.appConf);

  const loadGrecaptchaScript = useCallback((siteKey: string) => {
    let grecaptchaScript = document.createElement("script");
    grecaptchaScript.src =
      "https://www.google.com/recaptcha/api.js?render=" + siteKey;
    document.getElementsByTagName("head")[0].appendChild(grecaptchaScript);
  }, []);

  const getGrecaptchaToken = useCallback(async (): Promise<string> => {
    let token: string = await window.grecaptcha.execute(
      appConf.captchaSiteKey,
      {
        action: `ct_webform`,
      }
    );
    return token;
  }, [appConf.captchaSiteKey]);

  return { loadGrecaptchaScript, getGrecaptchaToken };
};
