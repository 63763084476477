.personalDataForm {
  --primary-color: #075da7;
  --primary-border-color: #075da7;
  --primary-text-color: #fff;

  /* --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff; */
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #f70d1c;
  --secondary-border-color: #f70d1c;
  --secondary-text-color: #fff;
  --secondary-hover-color: #df0715;
  --secondary-hover-border-color: #df0715;
  --secondary-hover-text-color: #fff;

  font-size: 16px;
  padding: 32px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;
}

.action {
  flex: 1 1 50%;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--form-text-color);
}

div.customPhoneInput>div>i {
  color: var(--form-arrow-color) !important;
  font-size: 16px !important;
}

.disclaimerButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--secondary-color-palette);
}

.disclaimer {
  font-size: 14px;
  line-height: 1.33;
}

.disclaimerRow {
  flex-direction: row;
}

.disclaimer i {
  margin-left: 0.5rem;
  padding-top: 1rem;
  cursor: pointer;
}

.disclaimerCheckBox {
  max-width: fit-content;
  margin-right: -6px;
}

.expContent {
  font-size: 12px;
  line-height: 1.33;
  padding-top: 10px;
  text-align: justify;
}

.btnHolder {
  margin-top: 12px;
  text-align: center;
}

div.customFieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 993px) {
  .action {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }

  div.customFieldRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
}