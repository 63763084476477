import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Define a type for the slice state
export type AppConfState = {
  captchaEnabled: boolean;
  captchaSiteKey: string;
  environment: "test" | "prep" | "prod";
  logEnabled: string;
};

// Define the initial state using that type
const initialState: AppConfState = {
  captchaEnabled: true,
  captchaSiteKey: "",
  environment: "test",
  logEnabled: "false",
};

export const AppConfSlice = createSlice({
  name: "appConf",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAppConf: (
      _appConf: AppConfState,
      action: PayloadAction<AppConfState>
    ) => {
      return { ...action.payload };
    },
  },
});

export const { setAppConf } = AppConfSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStatus = (state: RootState) => state;

export default AppConfSlice.reducer;
