export const TRANSLATIONS_FR = {
  screens: {
    personalData: {
      mr: "M.",
      mrs: "Mme",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Adresse e-mail",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired: "Format composé uniquement de chiffres, par ex.: 7812234567",
      gtcAgree: "Je consens au <Terms>traitement de mes données</Terms>.",
      gtcAgreeDesc: "En saisissant vos données dans les champs ci-dessous, vous autorisez automatiquement Cornèrcard (Cornèr Banque SA) à traiter vos données personnelles afin d'être contacté (par e-mail ou par téléphone), ainsi que pour recevoir des informations et des annonces publicitaires, concernant les produits et services qui, selon Cornèrcard, peuvent vous intéresser (information et publicité directe). Vos données personnelles seront traitées avec le plus grand soin et conformément aux dispositions de la Loi fédérale suisse sur la protection des données.<br/><br/>Vous pouvez à tout moment refuser de recevoir des informations (blocage de la publicité) ou, de manière générale, révoquer tout consentement donné précédemment concernant le traitement des données à des fins publicitaires. Pour ce faire, vous devez envoyer à Cornèr une demande écrite y relative, y compris par e-mail.",
      termsLink: "https://www.cornertrader.ch/export/sites/cornertrader/.content/.galleries/downloads/website/New-General-Terms-and-Conditions.pdf",
      submit: "Demandez-la",
    },
  },
  errors: {
    input: {
      invalidPattern: "Format incorrect",
      minLength: "Au moins {{min}} caractères nécessaires",
      maxLength: "Un maximum de {{max}} caractères est autorisé",
      minPhoneLength: "Le numéro de téléphone doit contenir au moins {{min}} chiffres.",
      required: "Champ obligatoire",
      mismatch: "{{fieldName}} ne correspond pas",
    },
    submit: "Une erreur s’est produite",
  },
  captchaDisclaimer: "Ce site est protégé par reCAPTCHA et les <0>règles de confidentialité</0><br/> ainsi que les <1>conditions d'utilisation</1> de Google s'appliquent.",
};
